import React, { useState } from 'react';
import { db, storage } from './firebaseconfig';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";

const OfferPage = () => {
  const [couponCodes, setCouponCodes] = useState(['', '', '', '']);
  const [images, setImages] = useState([null, null, null, null]);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState([0, 0, 0, 0]);

  const handleCouponCodeChange = (index, e) => {
    const newCouponCodes = [...couponCodes];
    newCouponCodes[index] = e.target.value;
    setCouponCodes(newCouponCodes);
  };

  const handleImageChange = (index, e) => {
    const newImages = [...images];
    newImages[index] = e.target.files[0];
    setImages(newImages);
  };

  const handleUpload = async () => {
    setIsUploading(true);

    const uploadedImageUrls = await Promise.all(
      images.map((image, index) => {
        if (!image) return null;

        const storageRef = ref(storage, `offers/${couponCodes[index]}/image_${index + 1}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        return new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress((prevProgress) => {
                const newProgress = [...prevProgress];
                newProgress[index] = prog;
                return newProgress;
              });
            },
            (error) => reject(error),
            async () => {
              const downloadURL = await getDownloadURL(storageRef);
              resolve(downloadURL);
            }
          );
        });
      })
    );

    const filteredUrls = uploadedImageUrls.filter(url => url !== null);
    const couponData = couponCodes.map((code, index) => ({
      couponCode: code,
      imageUrl: filteredUrls[index] || null,
    }));

    const offersCollectionRef = collection(db, 'offers');
    await addDoc(offersCollectionRef, {
      coupons: couponData,
    });

    setIsUploading(false);
    setCouponCodes(['', '', '', '']);
    setImages([null, null, null, null]);
    setProgress([0, 0, 0, 0]);
  };

  return (
    <div style={styles.container}>
      <h2>Add Offer</h2>
      
      {couponCodes.map((couponCode, index) => (
        <div key={index} style={styles.inputContainer}>
          <label>Coupon Code {index + 1}:</label>
          <input
            type="text"
            value={couponCodes[index]}
            onChange={(e) => handleCouponCodeChange(index, e)}
            style={styles.input}
          />
        </div>
      ))}
      
      <div style={styles.imagesContainer}>
        {images.map((image, index) => (
          <div key={index} style={styles.imageInputContainer}>
            <label>Image {index + 1}:</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(index, e)}
              style={styles.fileInput}
            />
            {isUploading && <progress value={progress[index]} max="100" />}
          </div>
        ))}
      </div>
      
      <button onClick={handleUpload} disabled={isUploading} style={styles.uploadButton}>
        {isUploading ? 'Uploading...' : 'Upload Offer'}
      </button>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '500px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  inputContainer: {
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  imagesContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    marginBottom: '20px',
  },
  imageInputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileInput: {
    fontSize: '16px',
    padding: '10px',
  },
  uploadButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
  },
};

export default OfferPage;
