import { initializeApp, getApps, getApp } from 'firebase/app';
import { initializeAuth, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyCJxNkfBoAL4JSDnZd1hakXuoK50CcRWxc",
  authDomain: "malon-50ccc.firebaseapp.com",
  projectId: "malon-50ccc",
  storageBucket: "malon-50ccc.appspot.com",
  messagingSenderId: "889150600955",
  appId: "1:889150600955:web:d959b2ad10dca4319ca25e",
  measurementId: "G-LT4QBS5M2K"
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}

const auth = initializeAuth(app, {
  persistence: browserLocalPersistence
});

const db = getFirestore(app);
const database = getDatabase(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, db, database, storage };
