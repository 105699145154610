import React, { useState } from 'react';
import { db, storage } from './firebaseconfig'; // Ensure you import storage
import { useNavigate } from 'react-router-dom';
import './Style/addsaloon.css';
import { collection, doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AddSalon = () => {
  const [salonDetails, setSalonDetails] = useState({
    name: '',
    type: '',
    
    address: '',
    latitude: '',
    longitude: '',
    openingHours: '',
    closingHours: '',
    phoneNumber: '',
    barberEmail: '',
    about: '', // Add about field
  });

  const [saloonId, setSaloonId] = useState('');
  const [serviceTitle, setServiceTitle] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [servicePrice, setServicePrice] = useState('');
  const [serviceQuantity, setServiceQuantity] = useState('');
  const [serviceTime, setServiceTime] = useState('');
  const [serviceGender, setServiceGender] = useState('');
  const [services, setServices] = useState([]);
  const [isAddingServices, setIsAddingServices] = useState(false);
  const [serviceIdCounter, setServiceIdCounter] = useState(1);
  const [images, setImages] = useState(Array(6).fill(null)); // State to hold up to 6 images
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSalonDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleImageChange = (index, e) => {
    if (e.target.files && e.target.files[0]) {
      const newImages = [...images];
      newImages[index] = e.target.files[0]; // Update the specific image slot
      setImages(newImages);
    }
  };

  const handleAddService = () => {
    const newService = {
      id: serviceIdCounter,
      name: serviceName || null,
      price: servicePrice ? parseFloat(servicePrice) : null,
      quantity: serviceQuantity ? parseInt(serviceQuantity) : null,
      time: serviceTime || null,
    };

    const existingServiceIndex = services.findIndex(
      (service) => service.title === serviceTitle
    );

    if (existingServiceIndex !== -1) {
      const updatedServices = [...services];
      if (salonDetails.type === 'unisex') {
        updatedServices[existingServiceIndex][serviceGender].push(newService);
      } else {
        updatedServices[existingServiceIndex].services.push(newService);
      }
      setServices(updatedServices);
    } else {
      if (salonDetails.type === 'unisex') {
        setServices([
          ...services,
          {
            title: serviceTitle || null,
            male: serviceGender === 'male' ? [newService] : [],
            female: serviceGender === 'female' ? [newService] : [],
          },
        ]);
      } else {
        setServices([
          ...services,
          {
            title: serviceTitle || null,
            services: [newService],
          },
        ]);
      }
    }

    setServiceIdCounter((prevCounter) => prevCounter + 1);
    setServiceName('');
    setServicePrice('');
    setServiceQuantity('');
    setServiceTime('');
    setServiceGender('');
    setIsAddingServices(false);
  };

  const handleAddServiceDetail = () => {
    if (serviceTitle) {
      setIsAddingServices(true);
    } else {
      alert('Please enter a service title.');
    }
  };

  const uploadImages = async () => {
    const imageUrls = [];
    for (const image of images) {
      if (image) { // Only upload if an image is selected
        const imageRef = ref(storage, `saloonImages/${saloonId}/${image.name}`);
        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);
        imageUrls.push(imageUrl);
      }
    }
    return imageUrls;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!saloonId) {
      alert('Please enter a salon ID.');
      return;
    }

    // Upload images and get their URLs
    const imageUrls = await uploadImages();

    const newSalonData = {
      ...Object.fromEntries(Object.entries(salonDetails).filter(([_, v]) => v !== undefined && v !== '')),
      images: imageUrls, // Save image URLs in the images array
      services: services.map(service => ({
        title: service.title || null,
        male: service.male || [],
        female: service.female || [],
        services: service.services || [],
      })),
    };

    console.log('Submitting data:', newSalonData);

    try {
      await setDoc(doc(db, 'saloons', saloonId), newSalonData);
      alert('Salon added successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error adding salon data: ', error);
      alert('Error adding salon data.');
    }
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#f4f4f4' }}>
      <h1>Add New Salon</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="saloonId"
          placeholder="Salon ID"
          value={saloonId}
          onChange={(e) => setSaloonId(e.target.value)}
          required
        />
        <input
          type="text"
          name="name"
          placeholder="Salon Name"
          value={salonDetails.name}
          onChange={handleInputChange}
          required
        />

        {/* Individual image upload inputs */}
        {images.map((image, index) => (
          <div key={index}>
            <label>Upload Image {index + 1}</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(index, e)}
            />
          </div>
        ))}

        <input
          type="text"
          name="type"
          placeholder="Salon Type (male, female, unisex)"
          value={salonDetails.type}
          onChange={handleInputChange}
          required
        />
        
        <input
          type="text"
          name="address"
          placeholder="Address"
          value={salonDetails.address}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="latitude"
          placeholder="Latitude"
          value={salonDetails.latitude}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="longitude"
          placeholder="Longitude"
          value={salonDetails.longitude}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="openingHours"
          placeholder="Opening Hours"
          value={salonDetails.openingHours}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="closingHours"
          placeholder="Closing Hours"
          value={salonDetails.closingHours}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="phoneNumber"
          placeholder="Phone Number"
          value={salonDetails.phoneNumber}
          onChange={handleInputChange}
          required
        />
        <input
          type="email"
          name="barberEmail"
          placeholder="Barber's Email"
          value={salonDetails.barberEmail}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="about"
          placeholder="About the Salon"
          value={salonDetails.about}
          onChange={handleInputChange}
        />

        <h2>Add Service</h2>
        {!isAddingServices ? (
          <>
            <input
              type="text"
              placeholder="Service Title"
              value={serviceTitle}
              onChange={(e) => setServiceTitle(e.target.value)}
              required
            />
            <button type="button" onClick={handleAddServiceDetail}>Add Service Details</button>
          </>
        ) : (
          <>
            <input
              type="text"
              placeholder="Service Name"
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Quantity"
              value={serviceQuantity}
              onChange={(e) => setServiceQuantity(e.target.value)}
              required
            />
            {salonDetails.type === 'unisex' ? (
              <>
                <select
                  value={serviceGender}
                  onChange={(e) => setServiceGender(e.target.value)}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <input
                  type="text"
                  placeholder="Time"
                  value={serviceTime}
                  onChange={(e) => setServiceTime(e.target.value)}
                  required
                />
              </>
            ) : (
              <input
                type="text"
                placeholder="Time"
                value={serviceTime}
                onChange={(e) => setServiceTime(e.target.value)}
                required
              />
            )}
            <input
              type="number"
              placeholder="Price"
              value={servicePrice}
              onChange={(e) => setServicePrice(e.target.value)}
              required
            />
            <button type="button" onClick={handleAddService}>Add Service</button>
          </>
        )}
        <button type="submit">Submit Salon</button>
      </form>
    </div>
  );
};

export default AddSalon;
