import React, { useState } from 'react';
import { auth } from './firebaseconfig'; // Update the path as needed
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Style/login.css'; // Import your CSS file for styling
import {  useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [resetEmail, setResetEmail] = useState('');

  const navigate = useNavigate(); // Hook for navigation

  const handleLogin = async () => {
    if (!email || !password) {
      toast.error('Please enter both email and password.');
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Login Successful! Welcome back!');
      navigate('/main'); // Redirect to the addsaloon page
    } catch (error) {
      toast.error(`Invalid Username or Password: ${error.message}`);
    }
  };

  const handlePasswordReset = async () => {
    if (!resetEmail) {
      toast.error('Please enter your email address.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      toast.success('Password Reset Email Sent! Please check your email to reset your password.');
      setModalVisible(false);
      setResetEmail(''); // Clear input after sending
    } catch (error) {
      toast.error(`Error Sending Reset Email: ${error.message}`);
    }
  };

  return (
    <div className='login'>
    <div className="container">
      <h1 className="header">Login</h1>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="input"
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="input"
      />
      <button className="loginButton" onClick={handleLogin}>Login</button>
      <button className="forgotPasswordButton" onClick={() => setModalVisible(true)}>Forgot Password?</button>
      <p className="signupText" onClick={() => navigate('/signup')}>Don't have an account? Sign Up</p>

      {modalVisible && (
        <div className="modal">
          <h2 className="modalText">Reset Password</h2>
          <input
            type="email"
            placeholder="Enter your email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            className="input"
          />
          <button className="resetButton" onClick={handlePasswordReset}>Send Reset Link</button>
          <button className="cancelButton" onClick={() => setModalVisible(false)}>Cancel</button>
        </div>
      )}

      <ToastContainer />
    </div>
    </div>
  );
};

export default Login;
