import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Component/login';
import AddSalon from './Component/Addsaloon';
import MainPage from './Component/MainPage';
import OfferPage from './Component/Offer';







function App() {
  
  

  

  return (
    <BrowserRouter>
     
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/salon" element={<AddSalon />} />
        <Route path="/offer" element={<OfferPage />} />
        
        
        
       

        
      </Routes>
    </BrowserRouter>
  );
}

export default App;