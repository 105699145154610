// src/Component/MainPage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import


const MainPage = () => {
  const navigate = useNavigate(); // Updated hook

  const navigateToAddSalon = () => {
    navigate('/salon');
  };

  const navigateToOfferPage = () => {
    navigate('/offer');
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Welcome to the Admin Panel</h1>
      <div style={styles.buttonContainer}>
        <button onClick={navigateToAddSalon} style={styles.button}>
          Go to Add Salon Page
        </button>
        <button onClick={navigateToOfferPage} style={styles.button}>
          Go to Offer Page
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
    textAlign: 'center',
  },
  header: {
    marginBottom: '20px',
    fontSize: '24px',
    color: '#333',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    transition: 'background-color 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
};

export default MainPage;
